import React, { useState } from 'react'
import Layout from '../../components/common/Layout'
import Fade from 'react-reveal/Fade'
import CTA from '../../components/common/CTA'
import Docs from '../../components/help/Docs'
import { categories } from '../../data/categories'

const GuidesPage = ({ data }) => {
  const guides = data.docs.nodes
    .filter(node => node.frontmatter.template === 'SingleArticle')
    .flatMap(node => node.frontmatter)

  return (
    <Layout
      title="Atlas Guides - Learn how to use Atlas"
      meta={{
        description:
          'Start with the basics, or explore advanced features to become an Atlas expert. Learn how to use Atlas to create beautiful maps and visualizations.',
        title: 'Atlas Guides - Learn how to use Atlas',
        type: 'website'
      }}
    >
      <main>
        <Docs
          data={guides}
          categories={categories['guides']}
          title="Guides"
          subtitle="Start with the basics, or explore advanced features to
                    become an Atlas expert"
        />
        <Fade up duration={1000} delay={0} distance="30px">
          <CTA text="Ready to level up your map-making process?" />
        </Fade>
      </main>
    </Layout>
  )
}

export default GuidesPage

export const pageQuery = graphql`
  query {
    docs: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/guides/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage
          featured
          template
          readTime
          date(formatString: "MMMM D, YYYY")
          topic
          author {
            name
            role
          }
        }
        fields {
          slug
        }
      }
    }
  }
`
